import React from "react";
import { GiWoodFrame } from "react-icons/gi";

import {
  PageTitle,
  ServicesDesc,
  ServicesWrapper,
  ServicePointWrapper,
  ServicePoint,
} from "./ServicesElements";
import { text } from "../../../data";

const Services = ({ page }) => {
  const { servicesDesc, services } = text;

  return (
    <>
      <PageTitle>{page}</PageTitle>
      <ServicesDesc>{servicesDesc}</ServicesDesc>
      <ServicesWrapper>
        {services.map((service, index) => {
          return (
            <ServicePointWrapper key={service + index}>
              <GiWoodFrame size="20px" />
              <ServicePoint>{service}</ServicePoint>
            </ServicePointWrapper>
          );
        })}
      </ServicesWrapper>
    </>
  );
};

export default Services;
