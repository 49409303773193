import React from "react";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavBusName,
  NavBusNameContainer,
  NavBusName1,
  NavBusName2,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
import { text } from "../../data";
import navLogo from "../../images/logo-white.png";

const Navbar = ({ toggle }) => {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavBusName
            id="logo"
            to="home"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            activeClass="homeLogo"
            className="navLinks"
          >
            <NavLogo src={navLogo} />
            <NavBusNameContainer>
              <NavBusName1>IMC Elite</NavBusName1>
              <NavBusName2>CONSTRUCTION</NavBusName2>
            </NavBusNameContainer>
          </NavBusName>

          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            {text.pages.map((page, i) => {
              return (
                <NavItem key={"navbar" + i}>
                  {page !== "Contact" && (
                    <NavLinks
                      to={page}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      activeClass="active"
                      offset={-60}
                      className={"navLinks " + page}
                    >
                      {page}
                    </NavLinks>
                  )}
                </NavItem>
              );
            })}
          </NavMenu>
          <NavBtn>
            <NavBtnLink
              to="Contact"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-60}
              className={"navLinks Contact"}
            >
              Contact Us
            </NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
