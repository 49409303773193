import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarDiv,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
} from "./SidebarElements";
import { text } from "../../data";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          {text.pages.map((page, i) => {
            return (
              <SidebarDiv key={"sidebar" + i}>
                {page !== "Contact" && (
                  <SidebarLink
                    to={page}
                    onClick={toggle}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    activeClass="active"
                    offset={-60}
                    className={"navLinks " + page}
                  >
                    {page}
                  </SidebarLink>
                )}
              </SidebarDiv>
            );
          })}
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute
            to="Contact"
            onClick={toggle}
            offset={-60}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            className={"navLinks Contact"}
          >
            Contact
          </SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
