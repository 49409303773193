import React, { useState, useEffect, useRef } from "react";
import ImageGallery from "react-image-gallery";
import { ProjectsDesc, PageTitle } from "./ProjectsElements";
import { text, images } from "../../../data";

const Projects = ({ page }) => {
  const imgGalleryRef = useRef();
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (isFullScreen === true) {
      document
        .getElementsByClassName("fullscreen-modal")[0]
        .addEventListener("click", (e) => {
          if (e.target === e.currentTarget) {
            imgGalleryRef.current.exitFullScreen();
          }
        });

      const navLinks = document.querySelectorAll(".navLinks");

      navLinks.forEach((link) => {
        link.addEventListener("click", function handleClick(event) {
          //console.log("link clicked", event.target.classList);
          imgGalleryRef.current.exitFullScreen();
        });
      });
    }
  }, [isFullScreen]);

  return (
    <>
      <PageTitle>{page}</PageTitle>
      <ProjectsDesc>{text.projectDesc}</ProjectsDesc>

      <ImageGallery
        ref={imgGalleryRef}
        items={images}
        useBrowserFullscreen={false}
        // showPlayButton={false}
        // showFullscreenButton={false}
        onClick={() => {
          if (!isFullScreen) {
            imgGalleryRef.current.fullScreen();
          }
        }}
        onScreenChange={(fullScMode) => {
          setIsFullScreen(fullScMode);
        }}
      />
    </>
  );
};

export default Projects;
