import React from "react";

import {
  PageTitle,
  JobTypes,
  CareersDesc,
  BackgroundWrapper,
  BackgroundImg,
  ContactBtn,
} from "./CareersElements";

import bgImage from "../../../images/jobs.jpg";

//import { text } from "../../../data";

const Careers = ({ page }) => {
  return (
    <>
      <PageTitle>We're Hiring!</PageTitle>
      <JobTypes>CARPENTERS</JobTypes>
      <JobTypes>LABOURERS</JobTypes>
      <div style={{ height: "40px" }} />
      <CareersDesc>
        We are always looking for skilled and motivated people.
      </CareersDesc>
      <CareersDesc>Ready to join our team?</CareersDesc>
      <ContactBtn
        to="Contact"
        smooth={true}
        duration={500}
        spy={true}
        exact="true"
        activeClass="active"
        offset={-60}
      >
        Contact us now!
      </ContactBtn>
      <BackgroundWrapper>
        <BackgroundImg src={bgImage} />
      </BackgroundWrapper>
    </>
  );
};

export default Careers;
