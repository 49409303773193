import styled from "styled-components";
import { Link as LinkS } from "react-scroll";

export const PageTitle = styled.h1`
  margin-bottom: 40px;
  font-size: 2.9rem;

  @media screen and (min-width: 1000px) {
    margin-top: -10px;
  }
`;

export const CareersDesc = styled.p`
  width: 75%;
  margin: 0 auto 10px;
  font-size: 1rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 420px) {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 280px) {
    font-size: 0.8rem;
  }
`;

export const JobTypes = styled.p`
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const BackgroundWrapper = styled.div`
  z-index: 0;
  overflow: hidden;
`;

export const BackgroundImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 0.15;
  overflow: hidden;
`;

export const ContactBtn = styled(LinkS)`
  background: #f9a825;
  white-space: nowrap;
  padding: 10px 25px;
  color: #010606;
  font-size: 18px;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  width: 200px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin: 1.5rem auto 2rem;
  z-index: 2;

  :hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }

  @media screen and (max-width: 768px) {
    margin: 1.5rem auto 0;
  }
`;
