import styled from "styled-components";

export const PageTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 2.7rem;
  color: #f9a825;

  @media screen and (max-width: 420px) {
    margin-top: -50px;
    margin-bottom: 50px;
  }

  @media screen and (min-width: 1000px) {
    margin-top: -10px;
    margin-bottom: 15px;
  }
`;

export const ProjectsDesc = styled.p`
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
  }
`;

export const ProjectSectionWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;
