import React from "react";

import {
  HeroSection,
  HeroWrapper,
  HeroButton,
  HeroImage,
  HeroOverlay,
  HeroContent,
} from "./HeroElements";
import heroImg from "../../images/imc1.jpg";
import { text } from "../../data";

const Hero = () => {
  return (
    <HeroSection id={"home"}>
      <HeroWrapper>
        <HeroImage src={heroImg} />
        <HeroOverlay />
        <HeroContent>
          <h1>{text.businessName}</h1>
          <p>{text.hero}</p>
          <HeroButton
            to="Contact"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            activeClass="active"
            offset={-60}
          >
            Contact Us
          </HeroButton>
        </HeroContent>
      </HeroWrapper>
    </HeroSection>
  );
};

export default Hero;
