import React, { useState } from "react";
import { FaPhoneAlt, FaFacebookF } from "react-icons/fa";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { BsInstagram } from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import { ThemeProvider } from "styled-components";

import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

import {
  PageTitle,
  ContactDesc,
  ContactInfoBox,
  IconContainer,
  IconLink,
  Copyright,
  HorizontalLine,
  Location,
  Email,
  Phone,
  FormWrapper,
  FormDiv,
  InputWrapper,
  Label,
  Input,
  TextArea,
  BtnWrapper,
  Button,
  ErrorContainer,
  ErrorTitle,
  ErrorContent,
  ErrorOverlay,
  ErrorBtn,
} from "./ContactElements";
import { text } from "../../../data";

const Contact = () => {
  const { location, phone, email } = text.contact;

  const recaptcha = process.env.REACT_APP_G_RECAPTCHA;
  const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

  const [showError, setShowError] = useState({
    show: false,
    text: "",
    title: "",
  });

  const [formInfo, setFormInfo] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    "g-recaptcha-response": null,
  });

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendForm = () => {
    const { name, email, phone, message } = formInfo;

    if (name === "" || email === "" || phone === "" || message === "") {
      setShowError({
        show: true,
        text: "Please fill out the required (*) fields.",
        title: "Missing Fields",
      });
    } else if (!validateEmail(email)) {
      setShowError({
        show: true,
        text: "Please enter a valid email address.",
        title: "Invalid Email",
      });
    } else {
      emailjs.send(serviceID, templateID, formInfo, publicKey).then(
        (result) => {
          console.log(result.text);
          setShowError({
            show: true,
            text: "Thank you for your message. We will aim to get back to you as soon as we can.",
            title: "Message Sent",
          });
          setFormInfo({ name: "", email: "", phone: "", message: "" });
        },
        (error) => {
          console.log(error.text);
          setShowError({
            show: true,
            text: 'Please make sure to select "I\'m not a robot" before sending your message.',
            title: "Something went wrong",
          });
        }
      );
    }
  };

  return (
    <>
      <ThemeProvider
        theme={{
          reactDatepicker: {
            datepickerZIndex: 5,
            inputMinHeight: "40px",
            dateSingleDatepickerWrapperPosition: "absolute",
          },
        }}
      >
        <FormWrapper>
          <FormDiv>
            <PageTitle>CONTACT</PageTitle>
            <ContactDesc>
              If you have any questions or just want to get in touch, you can
              use the form below. We look forward to hearing from you.
            </ContactDesc>
            <InputWrapper>
              <Label htmlFor="name" style={{ marginRight: "13px" }}>
                Name*
              </Label>
              <Input
                type="text"
                name="name"
                value={formInfo.name}
                onChange={(e) =>
                  setFormInfo({ ...formInfo, name: e.target.value })
                }
              />
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="email" style={{ marginRight: "16px" }}>
                Email*
              </Label>
              <Input
                type="email"
                name="email"
                value={formInfo.email}
                onChange={(e) =>
                  setFormInfo({ ...formInfo, email: e.target.value })
                }
              />
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="phone">Phone*</Label>
              <Input
                type="number"
                name="phone"
                autoComplete="nope"
                value={formInfo.phone}
                onChange={(e) =>
                  setFormInfo({ ...formInfo, phone: e.target.value })
                }
              />
            </InputWrapper>
            <div style={{ height: "5px" }} />
            <Label htmlFor="message">Message*</Label>
            <TextArea
              name="message"
              value={formInfo.message}
              onChange={(e) =>
                setFormInfo({ ...formInfo, message: e.target.value })
              }
            />
            <ReCAPTCHA
              sitekey={recaptcha}
              onChange={(value) =>
                setFormInfo({ ...formInfo, "g-recaptcha-response": value })
              }
            />
            <BtnWrapper>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  sendForm();
                }}
              >
                Send
              </Button>
            </BtnWrapper>

            <HorizontalLine />
            <ContactInfoBox>
              <FaPhoneAlt />
              <Phone href={"tel:" + phone}>{phone}</Phone>
            </ContactInfoBox>
            <ContactInfoBox>
              <MdEmail size="20px" />
              <Email data-tip="Email address copied!">{email}</Email>
              <ReactTooltip
                class="tooltipStyle"
                event="click"
                place="bottom"
                afterShow={() => {
                  navigator.clipboard.writeText(email);
                }}
              />
            </ContactInfoBox>
            <ContactInfoBox>
              <MdLocationOn size="20px" />
              <Location>{location}</Location>
            </ContactInfoBox>

            <IconContainer>
              <IconLink target="_blank" href="https://www.facebook.com/">
                <FaFacebookF size="22px" />
              </IconLink>
              <IconLink target="_blank" href="https://www.instagram.com/">
                <BsInstagram size="24px" />
              </IconLink>
            </IconContainer>
          </FormDiv>
        </FormWrapper>

        {showError.show && (
          <>
            <ErrorOverlay onClick={() => setShowError(false)} />
            <ErrorContainer>
              <ErrorTitle>{showError.title}</ErrorTitle>
              <ErrorContent>{showError.text}</ErrorContent>
              <ErrorBtn
                onClick={() =>
                  setShowError({ show: false, text: "", title: "" })
                }
              >
                OK
              </ErrorBtn>
            </ErrorContainer>
          </>
        )}
      </ThemeProvider>

      <Copyright>
        Copyright © {text.businessName} {new Date().getFullYear()}
      </Copyright>
    </>
  );
};

export default Contact;
