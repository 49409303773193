import styled from "styled-components";
import { Link as LinkS } from "react-scroll";

export const HeroSection = styled.section`
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const HeroWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

export const HeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;

export const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: calc(100% - 100px);
  color: #fff;

  @media screen and (max-width: 315px) {
    width: calc(100% - 50px);
  }

  h1 {
    font-size: clamp(1.2rem, 10vw, 3.5rem);
    font-weight: 400;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.9);
    text-align: left;
    margin-bottom: 1.5rem;
    margin-top: 60px;
    font-weight: 700;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  p {
    margin-bottom: 1.2rem;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
    width: 50%;

    @media screen and (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
  }
`;

export const HeroButton = styled(LinkS)`
  background: #f9a825;
  white-space: nowrap;
  padding: 10px 25px;
  color: #010606;
  font-size: 18px;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  width: 160px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin-top: 1.5rem;

  :hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }

  @media screen and (max-width: 768px) {
    margin: 1.5rem auto 0;
  }
`;
