import "./App.css";
import React, { useState } from "react";

import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Hero from "./components/HeroSection";
import Section from "./components/Section";
import { text } from "./data";

function App() {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  return (
    <>
      <Sidebar isOpen={navOpen} toggle={toggleNav} />
      <Navbar toggle={toggleNav} />
      <Hero />
      {text.pages.map((page, i) => {
        return (
          <Section
            key={page + "_section"}
            lightBg={(i + 1) % 2 === 0 ? true : false}
            page={page}
          />
        );
      })}
    </>
  );
}

export default App;
