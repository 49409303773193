import styled from "styled-components";

export const SectionContainer = styled.div`
  color: ${({ lightBg }) => (lightBg ? "#fff" : "#000")};
  background: ${({ lightBg }) => (lightBg ? "#263238" : "#dfe5e8")};
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 10px 0;
  }
`;

export const SectionIdPlacement = styled.div`
  height: 60px;
  margin-top: -60px;
`;

export const SectionWrapper = styled.div`
  z-index: 1;
  //height: calc(100vh - 50px);
  height: ${({ page }) =>
    page === "Contact" ? "calc(100vh - 50px)" : "calc(100vh - 50px)"};
  min-height: ${({ page }) =>
    page === "Contact" ? "1000px" : "calc(100vh - 50px)"};
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 40px;
  justify-content: center;

  @media screen and (width: 375px) and (height: 667px) {
    min-height: ${({ page }) =>
      page === "Contact" ? "900px" : "calc(100vh - 50px)"};
  }

  @media screen and (max-width: 393px) {
    padding: 0 30px;
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;
