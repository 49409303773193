import React from "react";
import {
  SectionContainer,
  SectionWrapper,
  SectionIdPlacement,
  ContentWrapper,
} from "./SectionElements";
import Services from "./Services";
import Projects from "./Projects";
import Contact from "./Contact";

import Careers from "./Careers";

import { text } from "../../data";

const Section = ({ lightBg, page }) => {
  return (
    <>
      <SectionIdPlacement />
      <SectionContainer lightBg={lightBg} id={page}>
        <SectionWrapper page={page}>
          <ContentWrapper>
            {page === text.pages[0] && <Services page={page} />}
            {page === text.pages[1] && <Projects page={page} />}
            {page === text.pages[2] && <Careers page={page} />}
            {page === text.pages[3] && <Contact page={page} />}
          </ContentWrapper>
        </SectionWrapper>
      </SectionContainer>
    </>
  );
};

export default Section;
