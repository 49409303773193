import styled from "styled-components";

export const PageTitle = styled.h1`
  margin-top: -100px;
  margin-bottom: 50px;
  font-size: 2.7rem;

  @media screen and (min-width: 1000px) {
    margin-top: -50px;
    margin-bottom: 35px;
  }
`;

export const ServicesDesc = styled.p`
  margin-bottom: 35px;

  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
  }
`;

export const ServicesWrapper = styled.div`
  width: 265px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 280px) {
    width: 190px;
  }
`;

export const ServicePointWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

export const ServicePoint = styled.p`
  margin-left: 8px;
  @media screen and (max-width: 280px) {
    font-size: 0.9rem;
  }
`;
