import img1 from "./images/projects/pic1_big.jpg";
import img1_s from "./images/projects/pic1_sm.jpg";
import img2 from "./images/projects/pic2_big.jpg";
import img2_s from "./images/projects/pic2_sm.jpg";
import img3 from "./images/projects/pic3_big.jpg";
import img3_s from "./images/projects/pic3_sm.jpg";
import img4 from "./images/projects/pic4_big.jpg";
import img4_s from "./images/projects/pic4_sm.jpg";
import img5 from "./images/projects/pic5_big.jpg";
import img5_s from "./images/projects/pic5_sm.jpg";

export const text = {
  businessName: "IMC Elite Construction",
  hero: "We offer a wide variety of steel frame installations",
  pages: ["Services", "Projects", "Jobs", "Contact"],
  contact: {
    location: "Melbourne, Victoria",
    phone: "0412698234",
    email: "info@imceliteconstruction.com.au",
    instagram: "imcinsta",
    facebook: "imcifb",
  },
  servicesDesc:
    "Steel frame installations of walls, joists, flooring, trusses and more for:",
  services: [
    "Single storey homes",
    "Double storey homes",
    "Duplexes",
    "Townhouses",
    "Semi-commercial top storeys",
  ],
  projectDesc: "See the gallery below for our previous work.",
};

export const images = [
  {
    original: img1,
    thumbnail: img1_s,
    originalAlt: "Steel frame installation picture",
    thumbnailAlt: "Steel frame installation picture",
  },
  {
    original: img2,
    thumbnail: img2_s,
    originalAlt: "Steel frame installation picture",
    thumbnailAlt: "Steel frame installation picture",
  },
  {
    original: img3,
    thumbnail: img3_s,
    originalAlt: "Steel frame installation picture",
    thumbnailAlt: "Steel frame installation picture",
  },
  {
    original: img4,
    thumbnail: img4_s,
    originalAlt: "Steel frame installation picture",
    thumbnailAlt: "Steel frame installation picture",
  },
  {
    original: img5,
    thumbnail: img5_s,
    originalAlt: "Steel frame installation picture",
    thumbnailAlt: "Steel frame installation picture",
  },
];
