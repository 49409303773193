import styled, { css } from "styled-components";

export const PageTitle = styled.h1`
  margin-top: 60px;
  margin-bottom: 20px;
  font-size: 2.7rem;
  color: #f9a825;

  @media screen and (max-width: 360px) {
    margin-bottom: 10px;
  }

  @media screen and (width: 375px) and (height: 667px) {
    font-size: 2.4rem;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 280px) {
    font-size: 1.9rem;
    margin-bottom: 10px;
  }
`;

export const ContactDesc = styled.p`
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const ContactInfoBox = styled.div`
  justify-content: center;
  padding-bottom: 10px;
  display: flex;
`;

export const Location = styled.p`
  margin-left: 10px;
  margin-bottom: 15px;
  text-decoration: none;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.7px;

  @media screen and (max-width: 360px) {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }

  @media screen and (width: 375px) and (height: 667px) {
    font-size: 0.85rem;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 280px) {
    font-size: 0.85rem;
    margin-bottom: 5px;
  }
`;

export const Phone = styled.a`
  margin-left: 9px;
  margin-bottom: 15px;
  text-decoration: none;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.7px;

  @media screen and (max-width: 360px) {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }

  @media screen and (width: 375px) and (height: 667px) {
    font-size: 0.85rem;
    margin-bottom: 5px;
  }
`;

export const Email = styled.a`
  margin-left: 13px;
  margin-bottom: 15px;
  text-decoration: none;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;

  @media screen and (max-width: 360px) {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 375px) {
    font-size: 14px;
  }

  @media screen and (width: 375px) and (height: 667px) {
    font-size: 0.85rem;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 280px) {
    font-size: 0.75rem;
    margin-bottom: 5px;
    margin-left: 3px;
  }
`;

export const IconContainer = styled.div`
  width: 150px;
  justify-content: space-evenly;
  display: flex;
  margin: 5px auto 40px;

  @media screen and (max-width: 375px) {
    margin-bottom: 0;
  }

  // @media screen and (width: 375px) and (height: 667px) {
  //   margin: 5px auto 10px;
  // }
`;

export const IconLink = styled.a`
  color: #fff;

  @media screen and (max-width: 280px) {
    width: 18px;
    height: 18px;
  }
`;

export const Copyright = styled.div`
  font-family: "Montserrat";
  font-size: 11px;
  color: grey;
  position: absolute;
  bottom: 10px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  @media screen and (max-width: 768px) {
    bottom: 5px;
  }
`;

export const HorizontalLine = styled.div`
  background: #f9a825;
  height: 3px;
  padding: 2px;
  background-image: repeating-linear-gradient(
    -55deg,
    #1b2429,
    #1b2429 20px,
    #f9a825 20px,
    #f9a825 40px
  );
  width: 100%;
  margin: 40px auto 80px;
`;

/// CONTACT FORM ////
export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: -50px;
`;

export const FormDiv = styled.form`
  width: 50%;

  @media screen and (max-width: 1026px) {
    width: 70%;
  }

  @media screen and (max-width: 769px) {
    width: 80%;
  }

  @media screen and (max-width: 420px) {
    width: 95%;
  }

  @media screen and (max-width: 280px) {
    max-width: 100%;
  }
`;

const sharedStyles = css`
  background-color: #f9f9f9;
  height: 40px;
  border: 1px solid #bcbec0;
  margin: 5px 0 10px 0;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 2px;

  @media screen and (max-width: 375px) {
    padding: 10px;
    margin: 8px 0 5px 0;
    height: 30px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DateAttachWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  @media screen and (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

export const Label = styled.p`
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: left;
  margin: 0 10px 5px 3px;
  flex: 5%;

  @media screen and (max-width: 375px) {
    font-size: 0.9rem;
  }

  @media screen and (width: 375px) and (height: 667px) {
    font-size: 0.85rem;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 280px) {
    font-size: 0.75rem;
  }
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  ${sharedStyles}
  flex: 85%
`;

export const TextArea = styled.textarea`
  background: #eee;
  width: 100%;
  min-height: 80px;
  resize: none;
  font-family: "Montserrat", sans-serif;
  ${sharedStyles}
  margin-top: 0;

  @media screen and (max-width: 375px) {
    font-size: 0.9rem;
    min-height: 60px;
  }

  @media screen and (width: 375px) and (height: 667px) {
    min-height: 50px;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  display: block;
  background: #f9a825;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  border: 0;
  height: 35px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border;
  font-family: "Montserrat", sans-serif;
  // margin-bottom: 10px;
  margin-top: 10px;

  @media screen and (max-width: 280px) {
    font-size: 0.8rem;
  }
`;

export const PlanInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const PlanInputLabel = styled.label`
  font-size: 0.9rem;
  min-height: 35px;
  color: #fff;
  background: #f9a825;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  padding: 8px 20px 0;
  display: flex;

  :hover {
    background: #000;
  }

  @media screen and (max-width: 280px) {
    font-size: 0.8rem;
    padding: 10px 20px 0;
  }
`;

export const ErrorOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
`;

export const PlanInputIcon = styled.div`
  margin-right: 8px;
  margin-top: 2px;
`;

export const ErrorContainer = styled.div`
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 1px 1px 10px 3px #888888;
  width: fit-content;
  padding: 30px 20px 30px;
  background: #f5f5f5;
  z-index: 5;
  max-width: 490px;
  margin-top -100px;

  @media screen and (max-width: 414px) {
    max-width: 90%;
  }
`;

export const ErrorTitle = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #000;
`;

export const ErrorContent = styled.p`
  font-size: 0.9rem;
  color: #000;
`;

export const ErrorBtn = styled.button`
  display: block;
  background: #f9a825;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  border: 0;
  height: 35px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border;
  font-family: "Montserrat", sans-serif;
  margin: 25px auto 0;
`;
